import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"
import { Col } from "react-bootstrap"
import MetaTags from "react-meta-tags"
import { Alert, CardBody } from "reactstrap"
import "../../components/assets/css/login.css"
import mainLogo from "../../components/assets/image/main-logo.png"

import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import Loader from "../../Loader/MaacLoader"
import { recoverAccount } from "../../store/actions"
const EmailVerify = () => {
  const { loading, message, error } = useSelector(state => ({
    loading: state.ForgetPassword.loading,
    message: state.ForgetPassword.forgetSuccessMsg,
    error: state.ForgetPassword.forgetError,
  }))
  let dispatch = useDispatch()
  let history = useHistory()
  let [email, setEmail] = useState("")
  const handleEmail = e => {
    e.preventDefault()
    console.log("email data here", email)
    dispatch(recoverAccount(email, history))
  }
  return (
    <React.Fragment>
      <div className="account-pages login-main-wrap">
        <MetaTags>
          <title>Email Verification</title>
        </MetaTags>

        <div className="login-left-logo">
          <img src={mainLogo} alt="" />
        </div>
        <div className="login-right-form">
          <div className="login-right-inner-form">
            <div className="p-2">
              {loading ? (
                <div className="p-4 d-flex justify-content-center align-items-center p-2 mb-3 mt-5 mr-auto">
                  <Loader />
                </div>
              ) : (
                <CardBody>
                  <div className="login-right-inner-form-inner-wrap-2">
                    <h2>Verify Your Email</h2>
                    <AvForm className="form-horizontal mt-4">
                      <div className="mb-3">
                        {error ? <Alert color="danger">{error}</Alert> : null}
                        <AvField
                          name="Email_Address"
                          label=""
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          type="email"
                          className="form-control"
                          placeholder="Enter Email Address"
                          required
                        />
                      </div>{" "}
                    </AvForm>
                    <Col className="text-end">
                      <div className="input-field-group submit-btn mb-3  verify-email-page">
                        <button
                          onClick={e => handleEmail(e)}
                          type="submit"
                          className="calibarX-default"
                        >
                          Submit
                        </button>
                      </div>
                    </Col>
                  </div>
                </CardBody>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EmailVerify
