import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { Alert, CardBody, Col, Row } from "reactstrap"
import "../../components/assets/css/login.css"
import longArrow from "../../components/assets/image/ButtonLongArrow.png"
import mainLogo from "../../components/assets/image/main-logo.png"
// actions
import Loader from "../../Loader/MaacLoader"
import { loginUser } from "../../store/actions"
const Login2 = () => {
  const { loading } = useSelector(state => ({ ...state.Login }))
  const { error } = useSelector(state => ({ ...state.loginError }))
  let history = useHistory()
  const dispatch = useDispatch()
  const [checkerror, setCheckError] = useState("")
  const [showPass, setShowPass] = useState(false)
  // if (token) {
  //   history.goBack(`/${role}dashboard`)
  // }
  //check email
  const validateEmail = email => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }
  const handleValidSubmit = (event, values) => {
    console.log(values)
    const { email, password } = values
    if (!email || !password) {
      console.log("Field must not be empty")
      return
    } else if (!validateEmail(email)) {
      setCheckError("Email is not valid")
      return
    } else {
      dispatch(loginUser(values, history))
    }
  }

  return (
    <React.Fragment>
      <div className="account-pages login-main-wrap">
        <MetaTags>
          <title>Login | GameProject</title>
        </MetaTags>

        {/* <div
          className="accountbg"
          style={{
            background: `url(${bg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div> */}
        <div className="login-left-logo">
          <img src={mainLogo} alt="" />
        </div>
        <div className="login-right-form">
          <div className="login-right-inner-form">
            <div className="p-2">
              {/* <div className="text-center mt-4">
                <Link to="/">
                  <img src={mainLogo} height="22" alt="logo" />
                </Link>
              </div>

              <h4 className="font-size-18 mt-5 text-center">Welcome Back !</h4>
              <p className="text-muted text-center">
                Sign in to continue to Veltrix.
              </p> */}
              {loading ? (
                <div className="p-4 d-flex justify-content-center align-items-center p-2 mb-3 mt-5 mr-auto">
                  <Loader />
                </div>
              ) : (
                <CardBody className="login-right-form">
                  <div className="login-right-inner-form-inner-wrap-2">
                    <h2>Log In</h2>
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {error || checkerror ? (
                        <Alert color="danger">{error || checkerror}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email Address"
                          value=""
                          className="form-control"
                          placeholder="Enter email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <div className="password-form-area-show">
                          <AvField
                            name="password"
                            label="Password"
                            value=""
                            type={showPass ? "text" : "password"}
                            required
                            placeholder="Enter Password"
                          />
                          <i onClick={e => setShowPass(!showPass)}>
                            {showPass ? (
                              <i className="bx bx-hide"></i>
                            ) : (
                              <i className="bx bx-show"></i>
                            )}
                          </i>
                        </div>
                      </div>

                      <Row className="mb-3">
                        {/* <Col sm={12}>
                          <div className="input-field-group">
                            <p>Remember</p>
                            <div className="input-radio-group">
                              <input
                                type="radio"
                                id=""
                                name="fav_language"
                                value="save"
                              />
                              <input
                                type="radio"
                                id=""
                                name="fav_language"
                                value="nosave"
                                defaultChecked
                              />
                            </div>
                          </div>
                        </Col> */}
                        <Col sm={12} className="text-end">
                          <div className="input-field-group submit-btn">
                            <button type="submit" className="calibarX-default">
                              {/* <button type="submit" className="login-btn-hover"> */}
                              Log In <img src={longArrow} alt="" />
                            </button>
                          </div>
                          {/* <button
                            className="btn btn-primary waves-effect waves-light"
                            type="submit"
                          >
                            Log In
                          </button> */}
                        </Col>
                      </Row>
                      <Row className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                          <Link to="/email-verify">
                            <i className="mdi mdi-lock"></i> Forgot your
                            password?
                          </Link>
                        </div>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              )}

              {/* <div className="mt-5 pt-4 text-center">
                <p>
                  Don't have an account ?{" "}
                  <Link
                    to="pages-register-2"
                    className="fw-medium-medium text-primary"
                  >
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Veltrix. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger"></i> by Themesbrand
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default Login2
