import { Button, Col, Container, Row } from "react-bootstrap"
import "./material.css"
const TeacherMaterial = () => {
  return (
    <div className="material-main-wrap">
      <Container>
        <div className="material-title">
          <h3>Accompanying Materials</h3>
        </div>
        <Row>
          <Col lg={8}>
            <div className="material-left-content">
              {/* <h4>Instructor manuals</h4> */}
              <div className="material-items-wrap">
                <div className="material-single-item">
                  <div className="material-single-item-left">
                    <h6>Case Description</h6>
                    <p>
                      This document describes the use of the simulation
                      platform.
                    </p>
                  </div>
                  <div className="material-single-item-right">
                    <>
                      <a
                        className="material-left-view-btn btn btn-primary"
                        href="https://staging-ieltsx.sgp1.digitaloceanspaces.com/1678354419662-CaseStudy.pdf"
                        target="_blank"
                        style={{ color: "#fff" }}
                      >
                        Download
                      </a>
                    </>
                  </div>
                </div>

                <div className="material-single-item">
                  <div className="material-single-item-left">
                    <h6>Student Manual</h6>
                    <p>
                      This document describes the use of the simulation
                      platform.
                    </p>
                    {/* <p>
                      <a
                        href="https://staging-ddc.sgp1.digitaloceanspaces.com/1662974169654-Updated%20market%20outlook_without%20Africa.docx
"
                      >
                        Download
                      </a>
                    </p> */}
                  </div>
                  <div className="material-single-item-right">
                    <>
                      <a
                        className="material-left-view-btn btn btn-primary"
                        href="https://staging-ddc.sgp1.digitaloceanspaces.com/1663048306269-Student%20Manual.pdf"
                        target="_blank"
                        style={{ color: "#fff" }}
                      >
                        Download
                      </a>
                    </>
                  </div>
                </div>
                {/* ------------------ */}
                {/* <div className="material-single-item">
                  <div className="material-single-item-left">
                    <h6>Instructor guide</h6>
                    <p>
                      This document describes the use of the simulation
                      platform.
                    </p>
                  </div>
                  <div className="material-single-item-right">
                    <Button className="material-left-view-btn">Download</Button>
                  </div>
                </div>
                
                <div className="material-single-item">
                  <div className="material-single-item-left">
                    <h6>Instructor guide</h6>
                    <p>
                      This document describes the use of the simulation
                      platform.
                    </p>
                  </div>
                  <div className="material-single-item-right">
                    <Button className="material-left-view-btn">Download</Button>
                  </div>
                </div> */}
              </div>
            </div>
          </Col>
          {/* <Col lg={4}>
            <div className="material-right-video">
               <ReactPlayer
                controls={true}
                light={true}
                className="material-video-player"
                url="https://www.youtube.com/watch?v=7sDY4m8KNLc"
              /> 
            </div>
          </Col> */}
        </Row>
      </Container>
    </div>
  )
}

export default TeacherMaterial
